"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.outOfContainerBounds = exports.doesOverlap = exports.randomIntFromInterval = exports.randomFloatFromInterval = void 0;
var randomFloatFromInterval = function (min, max) {
    return parseFloat((Math.random() * (max - min) + min).toFixed(4));
};
exports.randomFloatFromInterval = randomFloatFromInterval;
var randomIntFromInterval = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
exports.randomIntFromInterval = randomIntFromInterval;
// https://stackoverflow.com/a/12067046/744230
var doesOverlap = function (rect1, rect2, containerRect) {
    if (rect1.right > containerRect.right) {
        return (!(rect1.right < rect2.left ||
            rect1.left > rect2.right ||
            rect1.bottom < rect2.top ||
            rect1.top > rect2.bottom) || rect1.right - containerRect.right > rect2.left);
    }
    if (rect2.right > containerRect.right) {
        return (!(rect1.right < rect2.left ||
            rect1.left > rect2.right ||
            rect1.bottom < rect2.top ||
            rect1.top > rect2.bottom) || rect2.right - containerRect.right > rect1.left);
    }
    return !(rect1.right < rect2.left ||
        rect1.left > rect2.right ||
        rect1.bottom < rect2.top ||
        rect1.top > rect2.bottom);
};
exports.doesOverlap = doesOverlap;
var outOfContainerBounds = function (childRect, containerRect) {
    return childRect.bottom > containerRect.bottom;
};
exports.outOfContainerBounds = outOfContainerBounds;
// appending `|| childRect.right > containerRect.right` would disallow overflowing
// child elements from overflowing the right container edge. but that excludes a stripe
// on the right container edge of the min element width from being filled, which may look
// unnatural. so that's why we allow right overflow
